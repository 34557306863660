@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Saira+Extra+Condensed:wght@200&display=swap");

* {
  margin: 0;
  padding: 0;
}

*,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --_background-colour: #fff;
  --_contrast-colour: #878682;
  --_light-dark-contrast-colour: #2c2c2c;
  --_dark-colour: #000;
  --_light-colour: #f4f4f3;

  --_default-margin: 5vw;

  --_font-family-text: "Saira Extra Condensed";
  --_font-family-headings: "Audiowide";
  --_font-family-backup: sans-serif;

  --_max-width: 1280px;
}

#root {
  overflow: hidden;
}

a {
  font-family: var(--_font-family-text), var(--_font-family-backup);
  color: var(--_contrast-colour);
  text-decoration: none;
  cursor: pointer;
}

img {
  font-size: 1.5rem;
  font-family: var(--_font-family-text);
  color: var(--_background-colour);
  border-radius: 10px;
}

h1,
h2,
h3,
h4,
.text {
  line-height: 1.2em;
}

h1,
h2,
h3,
h4 {
  font-family: var(--_font-family-headings), var(--_font-family-backup);
}

p,
li {
  font-family: var(--_font-family-text), var(--_font-family-backup);
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 3.7rem;
}

h3,
p.larger {
  font-size: 2.7rem;
}

h4 {
  font-size: 1.7rem;
}

p.mid {
  font-size: 1.5rem;
}

p.smaller {
  font-size: 1.2rem;
}

p,
a,
li {
  font-size: 1rem;
  line-height: 1em;
  list-style-position: outside;
}

.wrapper {
  /* max-width: var(--_max-width); */
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

a.styled,
a.styled:visited {
  font-family: var(--_font-family-text), sans-serif;
  outline: 2px solid var(--_contrast-colour);
  color: var(--_contrast-colour);
  background-color: transparent;
  font-size: 1.2rem;
  padding: 1.2em;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
}

a.styled:hover {
  background-color: var(--_contrast-colour);
  color: var(--_background-colour);
}

div.not-found {
  /* max-width: var(--_max-width); */
  background-color: var(--_contrast-colour);
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.not-found p,
div.not-found a {
  color: var(--_background-colour);
  font-size: 2.5rem;
}

div.not-found a {
  text-decoration: underline;
}

div.not-found a:hover {
  color: var(--_dark-colour);
}

div.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  padding: 5vh 0vh;
  background-color: var(--_light-dark-contrast-colour);
}

nav a {
  font-size: 1.45rem;
  display: block;
  margin: auto 10px;
  color: var(--_background-colour);
}

nav a:hover {
  text-decoration: underline;
}

section.hero div.hero-top {
  max-width: var(--_max-width);
}

div.nav-left {
  width: 60%;
  max-width: calc(0.6 * var(--_max-width));
}

div.nav-right {
  width: 35%;
  max-width: calc(0.35 * var(--_max-width));
}

div.nav-bar nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100%;
}

a.active {
  font-weight: bold;
  text-decoration: underline;
}

div.nav-bar .nav-left h3 {
  display: block;
  position: relative;
  left: -11vw;
  color: var(--_background-colour);
  text-transform: uppercase;
}

section.hero {
  width: 100vw;
  background-color: var(--_light-dark-contrast-colour);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8vh;
}

section.hero div.hero-top,
section.hero div.hero-bottom,
section.hero div.hero-left,
section.hero div.hero-right {
  display: flex;
}

section.hero div.hero-top {
  flex-direction: row;
  height: calc(100vh - 36vh);
  margin: 0 5vw;
  justify-content: space-between;
}

section.hero div.hero-bottom {
  text-align: left;
  min-height: 15vh;
  background-color: var(--_contrast-colour);
  align-items: center;
  width: 100%;
  justify-content: center;
}

div.inner-wrap {
  max-width: var(--_max-width);
  display: block;
  width: 100vw;
}

section.hero div.hero-bottom h3 {
  color: var(--_background-colour);
}

section.hero div.hero-left {
  flex-direction: column;
  text-align: left;
  justify-content: center;
  width: 50%;
}

section.hero div.hero-left > * {
  margin: 20px;
  color: var(--_background-colour);
}

section.hero div.hero-left p {
  font-size: 1.8rem;
}

section.hero div.hero-left .listenButton {
  max-width: 200px;
  font-size: 1.5rem;
}

section.hero div.hero-right {
  align-items: center;
  padding-right: 6vw;
}

section.hero div.hero-right img {
  max-height: 40vh;
}

section.recent-episodes {
  display: flex;
  justify-content: center;
  text-align: left;
}

section.recent-episodes h2 {
  margin-bottom: 8vh;
}

section.recent-episodes div.episodes-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0vw, 1fr));
  gap: 5rem;
}

div.episode-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.episode-tile img {
  align-self: center;
  max-height: 40vh;
}

div.episode-tile h3,
div.episode-tile h4 {
  margin-top: 10px;
}

div.episode-tile .listen-button {
  max-width: 130px;
  font-size: 1.2rem;
  margin-top: 20px;
  background-color: var(--_contrast-colour);
  color: var(--_background-colour);
  font-weight: bold;
}

div.episode-tile .listen-button:hover {
  background-color: var(--_dark-colour);
  outline: 2px solid var(--_dark-colour);
}

section.meet-hosts {
  margin-top: 10vh;
  padding: 10vh 0;
  background-color: var(--_contrast-colour);
}

section.meet-hosts div.hosts-left {
  align-self: center;
}

section.meet-hosts div.hosts-left img {
  max-height: 50vh;
}

section.meet-hosts div.inner-wrap {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-evenly;
}

section.meet-hosts div.hosts-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

section.meet-hosts div.hosts-right > * {
  margin: 10px 0;
}

section.meet-hosts div.hosts-right a {
  color: var(--_background-colour);
  outline: 2px solid var(--_background-colour);
  /* max-width: 200px; */
  font-size: 1.3rem;
  font-weight: bold;
}

section.meet-hosts div.hosts-right p {
  font-size: 1.8rem;
}

section.meet-hosts div.hosts-right h3 {
  font-size: 3.7rem;
  color: var(--_background-colour);
}

section.meet-hosts div.hosts-right a:hover {
  background-color: var(--_background-colour);
  color: var(--_contrast-colour);
}

section.all-episodes {
  display: flex;
  justify-content: center;
  text-align: left;
}

section.all-episodes h2 {
  margin: 2vh 0 3vh 0;
}

section.all-episodes div.episodes-wrapper {
  /* grid-template-columns: repeat(auto-fit, minmax(10vw, 1fr)); */
  gap: 5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5vh;
}

section.all-episodes img {
  max-height: 30vh;
}

section.about-hero,
section.about-meet {
  display: flex;
  justify-content: center;
}

section.about-hero {
  background-color: var(--_light-dark-contrast-colour);
  padding: 1vh 0 4vh 0;
}

section.about-hero h2,
section.about-meet h2 {
  text-align: left;
}

section.about-hero h2,
section.about-hero p {
  color: var(--_background-colour);
}

section.about-hero p {
  font-size: 1.5rem;
  padding: 20px;
  text-align: justify;
  /* text-justify: inter-character; */
}

section.about-meet {
  margin: 3vh 0;
}

section.about-meet div.hosts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}

section.about-meet div.host {
  display: flex;
  flex-direction: row;
  width: calc(var(--_max-width) * 1);
  margin: 3vh 0;
  padding: 10px;
}

section.about-meet div.host h4 {
  margin: 10px 0;
  text-decoration: underline;
}

section.about-meet div.host ul {
  padding: 10px 20px;
  text-align: justify;
  /* text-justify: distribute; */
  list-style: square;
  list-style-position: inside;
}

section.about-meet div.host li,
section.about-meet div.host a {
  font-size: 1.35rem;
  margin: 5px 0;
}

section.about-meet div.host a {
  color: var(--_dark-colour);
  text-decoration: underline;
}

section.about-meet div.host a:hover {
  color: var(--_contrast-colour);
}

section.about-meet div.hosts img {
  margin: 1vh 0;
  max-width: 35vh;
}

section.about-meet div.meet-right,
section.about-meet div.meet-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

section.about-meet div.meet-left {
  align-items: center;
}

footer {
  padding: 9vh 0;
  background-color: var(--_light-dark-contrast-colour);
  color: var(--_background-colour);
}

footer div.inner-wrap {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-evenly;
}

footer li,
footer li a {
  list-style: none;
  text-align: left;
  padding: 3px 0;
  font-size: 1.3rem;
  text-decoration: underline;
  color: var(--_contrast-colour);
}

footer li:hover,
footer li a:hover {
  color: var(--_background-colour);
}

footer div.right-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

footer div.right-column p {
  font-size: 1.4rem;
}

/* TODO - animation on all elements on load */

@media only screen and (max-width: 600px) {
  div.nav-bar,
  section.hero,
  section.hero div.hero-left,
  section.recent-episodes div.inner-wrap {
    width: 100%;
  }

  div.nav-bar {
    flex-direction: column;
    padding: 2vh 0;
    align-items: center;
  }

  div.nav-left,
  div.nav-right {
    width: 100vw;
  }

  div.nav-bar a.styled {
    width: 150px;
    margin-top: 10px;
  }

  div.nav-bar div.nav-left h3 {
    left: 0;
  }

  div.nav-bar nav {
    flex-direction: column;
    align-items: center;
  }

  /* div.nav-bar nav {
    display: grid;
    grid-template-columns:repeat(3, 140px);
    repeat(auto-fit, minmax(20vw, 5fr));
    gap: 2rem;
  } */

  div.nav-bar nav > * {
    padding: 5px 0;
  }

  section.hero {
    margin-bottom: 4vh;
  }

  section.hero div.hero-top {
    flex-direction: column;
    height: auto;
  }

  section.hero div.hero-left {
    text-align: center;
    align-items: center;
  }

  section.hero div.hero-right img,
  section.meet-hosts div.hosts-left img,
  section.recent-episodes div.episode-tile img {
    max-height: 35vh;
  }

  section.hero div.hero-right {
    justify-content: center;
    padding-right: 0;
    margin-bottom: 5vh;
  }

  section.hero div.hero-bottom div.inner-wrap {
    padding: 30px;
  }

  section.recent-episodes div.inner-wrap {
    text-align: center;
    padding: 5px;
  }
  
  section.recent-episodes div.episodes-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(20vw, 1fr));
    gap: 3rem;
    display: flex;
    flex-direction: column;
  }

  section.recent-episodes h2 {
    margin-bottom: 4vh;
    text-align: center;
  }

  section.meet-hosts {
    margin-top: 5vh;
    padding: 5vh 0;
  }

  section.meet-hosts div.inner-wrap {
    flex-direction: column;
  }

  section.about-meet div.meet-left {
    align-items: center;
  }

  section.meet-hosts div.hosts-right {
    padding: 15px;
  }

  section.about-hero h2,
  section.about-meet h2,
  section.all-episodes h2 {
    text-align: center;
  }

  section.about-meet div.host {
    flex-direction: column;
    width: unset;
    margin: 1.5vh 0 0 0;
  }

  footer {
    padding: 2vh 0 5vh 0;
  }

  footer div.inner-wrap {
    flex-direction: column;
    text-align: left;
    padding: 30px;
  }

  footer div.inner-wrap > * {
    margin-top: 30px;
  }

  footer div.right-column {
    margin-top: 30px;
  }
}
